import Button from "antd/lib/button";
import Row from "antd/lib/row";
import Title from "antd/lib/typography/Title";
import React from "react";
import { useMediaQuery } from "react-responsive";

const BlockButton = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 1224 });

  return (
    <Row justify="center">
      <Button
        block={isMobile}
        size="large"
        type="primary"
        style={{
          borderRadius: 8,
          minHeight: 60,
          marginTop: 20,
          border: props.disabled ? "2px solid #DDDDD" : "2px solid #f1b442",
        }}
        {...props}
      >
        <Title
          level={4}
          style={{
            marginBottom: 0,
            display: "inline",
            margin: !isMobile ? "0 20px" : "auto",
            letterSpacing: "0.03em",
            color: props.disabled ? "inherit" : "white",
            fontWeight: "500",
          }}
        >
          {props.children}
        </Title>
      </Button>
    </Row>
  );
};

export default BlockButton;
