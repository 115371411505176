import Typography from "antd/lib/typography";
import React from "react";
import { useMediaQuery } from "react-responsive";

const { Title } = Typography;

const ResponsiveTitle = ({ children, level }) => {
  const isMobile = useMediaQuery({ maxWidth: 1224 });

  const desktopLevel = level ? level : 1;
  return (
    <Title
      level={isMobile ? 3 : desktopLevel}
      style={
        isMobile
          ? { marginTop: 25, marginBottom: 15 }
          : { marginTop: 20, marginBottom: 30 }
      }
    >
      {children}
    </Title>
  );
};

export default ResponsiveTitle;
